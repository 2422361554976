import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "@accrosoft-ltd/accropress-websites-components/dist/esm/seo"
import { Link } from "gatsby"

import config from "../../config.json"

require("es6-promise").polyfill()

const slugify = function slugify(text) {
  return text
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters

    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
}

export default function LocationsHomepage({
  pageContext: {
    integratorData,
    contentMetaData,
    contentDataData,
    navigationData,
    navResultData,
    entireLocationsList,
    customFieldsJSON,
  },
  props,
}) {
  return (
    <Layout {...props}>
      <SEO
        navResultData={navResultData}
        contentDataData={contentDataData}
        integratorData={integratorData}
        contentMetaData={contentMetaData}
      />

      <div className="bannerWrapper">
        <div
          className="st-philips-banner-image"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            backgroundImage: `url(/images/our-care-homes.jpg`,
          }}
        ></div>
        <div className="bannerTextWrapper">
          <div className="bannerText">
            <div className="bannerContent">
              <h1>Our Care Homes</h1>
              <p>
                We build stronger relationships with residents, their families
                and friends as well as health and social care workers,
                commissioners and local communities and try to make our homes
                very much a part of the local community.
              </p>
            </div>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          class="hb-curve"
        >
          <polygon fill="#fff" points="0,100 100,0 100,100"></polygon>
        </svg>
      </div>

      <div className="page__header-container page__header-container--blog-landing">
        <div className="container">
          <div
            className="row justify-content-center page__header page__header--landing blog-landing__header page__header--blog-landing"
            id="locations-homepage"
          >
            {entireLocationsList && entireLocationsList.length > 0 ? (
              <div className="locations-card-grid row inner-content grid-section box-theme-2 joblist-section col-xs-12 col-12">
                {entireLocationsList.map((location, i) => (
                  <>
                    {location.slug !== "/locations" && (
                      <div
                        className="col-12 col-lg-4 location-card-wrap"
                        key={i}
                      >
                        <div className="location-card-details">
                          <Link
                            className="location-card card location-card-link"
                            to={
                              location.parentSlug
                                ? location.parentSlug + location.slug
                                : location.slug
                            }
                          >
                            <div className="location-image">
                              <img
                                loading="lazy"
                                src={`https://cdn2.accropress.com/theme-assets/StPhilips/card-locations/${location.title}.jpg`}
                                alt={location.title}
                              />
                            </div>
                          </Link>
                          <div className="card-information">
                            <div className="location-card-header">
                              <Link
                                className="location-card card location-card-link"
                                to={
                                  location.parentSlug
                                    ? location.parentSlug + location.slug
                                    : location.slug
                                }
                              >
                                <div className="location-card-title">
                                  {location.title}
                                </div>
                              </Link>
                            </div>
                            <div className="location-card-meta">
                              <div className="location-card-meta-item">
                                {location.metaDescription}
                              </div>
                            </div>
                            <div className="location-address">
                              {location.address}
                            </div>
                          </div>

                          <div className="location-card-actions">
                            {location.customFieldsJSON &&
                            location.customFieldsJSON[0] &&
                            location.customFieldsJSON[0].children &&
                            location.customFieldsJSON[0].children[0] &&
                            location.customFieldsJSON[0].children[0].value ? (
                              <Link
                                className="location-card-link btn"
                                to={
                                  "/vacancies?sublocation=" +
                                  location.title +
                                  "&location=" +
                                  location.customFieldsJSON[0].children[0].value
                                }
                              >
                                View vacancies
                              </Link>
                            ) : (
                              <Link
                                className="location-card-link btn"
                                to={"/vacancies?sublocation=" + location.title}
                              >
                                View all vacancies
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            ) : (
              <p>No locations found</p>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}
